export function adsInit() {
    // if(!window.adsShow)return
    setTimeout(()=>{
        let childList = document.getElementsByClassName('ads__box');
        for (let i = 0; i < childList.length; i++) {
            let insNode = childList[i].firstChild;
            insNode.style.maxWidth = childList[i].clientWidth || childList[i].offsetWidth;
            (adsbygoogle = window.adsbygoogle || []).push({});
        }
    },500)

}